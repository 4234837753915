import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-side-stepper',
  templateUrl: './side-stepper.component.html',
  styleUrl: './side-stepper.component.less'
})
export class SideStepperComponent {
  profileuUrl : string = '';
  @Input() activeStep : string = '';
  // activeStep : string = 'offer'
  steps : any = [
    { key: 'dashboad', value: 'Dashboard', img : 'assets/images/icons/dashboard.png' },
    { key: 'locations', value: 'Location', img : 'assets/images/icons/location_pin.png' },
    { key: 'offers', value: 'Offer', img : 'assets/images/icons/award.png' },
    { key: 'profile', value: 'Profile', img : 'assets/images/icons/profile_tag.png' },
  ]

  constructor(
    private router: Router,
    private authenticationService : AuthenticationService
  ) {
    this.profileuUrl = this.authenticationService.currentUser.value.profile_image;
  }

  navigate(key: string): void {
    this.activeStep = key;
    this.router.navigate([`/${key}`]); // Navigating to the respective route
  }
}
