<div class="stepper">
    <div class="stepper--profile">
        <img class="stepper--profile__image" [src]="profileuUrl" alt="">
        <div class="stepper--profile__divider"></div>
    </div>
    <div class="stepper--action">
        <div class="stepper--action__step" *ngFor="let step of steps" (click)="navigate(step.key)"
            [class.active]="step.key === activeStep">
            <img class="stepper--action__step--icons" [src]="step.img" [alt]="step.key">
        </div>
    </div>
</div>