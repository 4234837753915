import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../app/shared/models/users';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  protected currentUserSubject: BehaviorSubject<User>;
  public currentUser: BehaviorSubject<User>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject;
  }

  private host = environment.host();

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    return this.currentUserSubject.value;
  }

  login(request: any) {
    // this.currentUserSubject.subscribe();
    return this.http.post<any>(this.host + 'login/', request).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        if (user && (user.token || user['Two-FA-Token'])) {
          user.login_time = new Date().getTime();
          user.tokenExpireTime =
            user.login_time + (Number(user.expires_in) - 600) * 1000;
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          user.header = true;
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('for_2fa_only', user['Two-FA-Token']);
          this.currentUserSubject.next(user);
          this.currentUser.next(user);
        }
        return user;
      })
    );
  }

  logoutUser() {
    const requestUrl = environment.host() + 'logout/';
    this.http
      .post(requestUrl, {})
      .pipe(catchError((err) => of(err)))
      .subscribe({
        next: () => {
          this.clearCredentials();
        },
      });
  }

  clearCredentials() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    var emptyuser: any = {};
    localStorage.clear();
    this.currentUserSubject.next(emptyuser);
    this.currentUser.next(emptyuser);
    // this.router.navigate(['/login']);
    location.reload();
  }

  updateCurrentUserObs(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  getCurrentUserObs() {
    return this.currentUserSubject;
  }

  getTenantDetail() {
    return this.http.get(this.host + 'tenants/basic-info/');
  }
}
