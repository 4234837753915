import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.less',
})
export class PaginationComponent {
  @Input() width: number = 940;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() heightUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() height: number = 17;
  @Input() previousUrl: string = '';
  @Input() nextUrl: string = '';
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}
  onClick(url: string) {
    this.navigate.emit(url);
  }
}
