<div
  class="otp-field"
  [ngClass]="{
    'otp-field--error': error
  }"
>
  <div class="otp-field__input">
    <input
      type="text"
      class="otp-field__input-cell"
      id="{{ fieldId }}optCell#{{ $index }}"
      *ngFor="let cellNumber of [].constructor(digitCount); let $index = index"
      (keyup)="onKeyUp($event, $index)"
      maxlength="1"
      autocomplete="off"
    />
  </div>
  <!-- <div class="otp-field__error" *ngIf="error" [title]="error">{{ error }}</div> -->
  <div class="otp-field__tooltip {{tooltipDirection}}" *ngIf="toolTipMessage.length > 0 && showError">
    <div class="otp-field__tooltip_message"
      [style]="{ width: tooltipWidth + 'px', height: tooltipHeight + 'px' }">
      {{ toolTipMessage }}
    </div>
  </div>
</div>
