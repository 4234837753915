import { ChangeDetectorRef, Component } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrl: './global-loader.component.less',
})
export class GlobalLoaderComponent {
  constructor() {}
}
