<div
  class="inline-loader"
  [ngStyle]="{
    height: height
  }"
>
  <img
    src="../../../../assets/images/icons/ripple.gif"
    alt="Loading"
    class="inline-loader__img"
  />
  <p class="inline-loader-text">{{ inlineLoaderText }}</p>
</div>
