import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrl: './inline-loader.component.less',
})
export class InlineLoaderComponent {
  @Input() height: string = '370px';
  @Input() inlineLoaderText: string = '';
}
