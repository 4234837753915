<div class="search-input">
  <input
    type="text"
    class="search-input__search-box"
    aria-label="search box"
    placeholder="Search"
    [(ngModel)]="searchValue"
  />
  <img
    class="search-input__search-icon"
    src="../../../../assets/images/icons/search@3x.png"
    alt="icon-search"
  />
</div>
