import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-field',
  templateUrl: './toggle-field.component.html',
  styleUrl: './toggle-field.component.less'
})
export class ToggleFieldComponent {
  @Input() toggleValue: boolean = false;
  @Output() toggleValueChange = new EventEmitter();
  @Input() fieldTitle?: string='wdefdssqwdfd';
  @Input() disabled?: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required:boolean = false;

  updateToggleValue() {
    if (this.disabled) {
      return;
    }
    this.toggleValue = !this.toggleValue;
    this.toggleValueChange.emit(this.toggleValue);
  }
}
