import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './lib/button/button.component';
import { CalenderComponent } from './lib/calender/calender.component';
import { CustomTextFieldComponent } from './lib/custom-text-field/custom-text-field.component';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from './lib/search-input/search-input.component';
import { OtpFieldComponent } from './lib/otp-field/otp-field.component';
import { PaginationComponent } from './lib/pagination/pagination.component';
import { SpacerComponent } from './lib/spacer/spacer.component';
import { CustomTextAreaFieldComponent } from './lib/custom-text-area-field/custom-text-area-field.component';
import { CustomCheckBoxComponent } from './lib/custom-check-box/custom-check-box.component';
import { CustomSelectDropdownComponent } from './lib/custom-select-dropdown/custom-select-dropdown.component';
import { ToggleFieldComponent } from './lib/toggle-field/toggle-field.component';
import { PlaceholderComponent } from './lib/placeholder/placeholder.component';
import { InlineLoaderComponent } from './lib/inline-loader/inline-loader.component';
import { SideStepperComponent } from './lib/side-stepper/side-stepper.component';
import { SearchSelectComponent } from './lib/search-select/search-select.component';
import { GlobalLoaderComponent } from './lib/global-loader/global-loader.component';

import { MultiSelectDropdownComponent } from './lib/multi-select-dropdown/multi-select-dropdown.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CalenderComponent,
    CustomTextFieldComponent,
    SearchInputComponent,
    OtpFieldComponent,
    PaginationComponent,
    SpacerComponent,
    CustomTextAreaFieldComponent,
    CustomCheckBoxComponent,
    CustomSelectDropdownComponent,
    ToggleFieldComponent,
    PlaceholderComponent,
    InlineLoaderComponent,
    SideStepperComponent,
    SearchSelectComponent,
    GlobalLoaderComponent,
    MultiSelectDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    ButtonComponent,
    CustomTextFieldComponent,
    SearchInputComponent,
    OtpFieldComponent,
    PaginationComponent,
    SpacerComponent,
    CustomTextAreaFieldComponent,
    CustomCheckBoxComponent,
    CustomSelectDropdownComponent,
    ToggleFieldComponent,
    PlaceholderComponent,
    InlineLoaderComponent,
    SideStepperComponent,
    SearchSelectComponent,
    MultiSelectDropdownComponent
  ]
})
export class LibraryModule { }
