<div
  class="search-select"
  [ngStyle]="{ width: width + widthUnit }"
  [ngClass]="{
    'search-select--open': !disabled && showDropdown
  }"
>
  <ul
    class="search-select__dropdown"
    #SelectDropdown
    [ngClass]="{
      'search-select__dropdown--open': !disabled && showDropdown,
      'search-select__dropdown--for-modal': forModal
    }"
  >
    <ng-container *ngIf="!loader; else contentLoader">
      <li
        class="search-select__dropdown-item"
        *ngFor="let option of options"
        tabindex="0"
        role="listitem"
        (keyup.enter)="emitSelection(option)"
        (click)="emitSelection(option)"
      >
        {{ option.value }}
      </li>
      <li
        *ngIf="options.length === 0"
        class="search-select__dropdown-item"
        tabindex="0"
        role="listitem"
      >
        No results found
      </li>
    </ng-container>
    <ng-template #contentLoader>
      <div class="search-select__dropdown-loader"></div>
    </ng-template>
  </ul>
  <app-custom-text-field
    [fieldTitle]="fieldTitle"
    [disabled]="disabled"
    [disableInput]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    [value]="displayValue"
    [showError]="showError"
    (valueChange)="searchKeyChange($event)"
    [delay]="delay"
    [width]="width"
    [includeErrorMsgPaddingFlag]="includeErrorMsgPaddingFlag"
    [highlightOnFocus]="highlightOnFocus"
    [autofocusField]="autofocusField"
    [defaultFocus]="defaultFocus"
    [errorMessage]="errorMessage"
    #inputField
  >
  </app-custom-text-field>
</div>
