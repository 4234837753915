<div
  class="custom-select-dropdown"
  [ngClass]="{ 'custom-select-dropdown--read-only': readOnly }"
>
  <div
    class="custom-select-dropdown-field"
    (mouseleave)="showDropdown = false"
    [ngClass]="{ 'custom-select-dropdown-field--selected': showDropdown }"
    [ngStyle]="{
      width: width + widthUnit,
      height: height + heightUnit
    }"
  >
    <ul
      class="custom-dropdown"
      [ngClass]="{
        'open-dropdown': !disabled && showDropdown,
        'custom-dropdown--for-modal': forModal
      }"
      #SelectDropdown
    >
      <div *ngFor="let option of options; trackBy: groupOptions">
        <li
          class="custom-dropdown-item"
          (click)="onSelect(option.key)"
          tabindex="0"
          role="listitem"
          (keyup.enter)="onSelect(option.key)"
        >
          <span class="custom-dropdown-item__overflow" [title]="option.value">{{
            option.value
          }}</span>
        </li>
      </div>
    </ul>
    <div class="custom-menu-text-field" #inputField>
      <div class="custom-menu-text-field__placeholder" *ngIf="fieldTitle">
        {{ fieldTitle }}:
      </div>
      <button
        type="text"
        class="custom-menu-text-field__input"
        readonly
        [ngClass]="{
          disabled: disabled,
          'custom-menu-text-field__input--placeholder': displayValue.length <= 0
        }"
        tabindex="0"
        role="textbox"
        (keyup.enter)="openDropdown()"
        (click)="openDropdown()"
        [value]="displayValue"
        [title]="
          displayValue && displayValue.toString().length > 0
            ? displayValue
            : !fieldTitle
            ? placeholder
            : ''
        "
        [ngStyle]="{ 'padding-left': !fieldTitle ? '15px' : '5px' }"
      >
        {{
          displayValue.length ? displayValue : !fieldTitle ? placeholder : ""
        }}
      </button>
    </div>
  </div>
</div>
