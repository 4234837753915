// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {// this is UAT environemnt.
	production: true,
    themeVariable: 'sanlam',
	version: '0.0.16',
	contentful: {
		spaceId: '',
		//Content delivery token
		token: ''
	},
	apiendpoint: {
		url: 'https://merchant-portal.loyaltycloud1.com/',
		version: 'v1/',
		endpoint: 'bolapi/'
	},
	h_program_id : 17,
	host: function () {
        return this.apiendpoint.url + this.apiendpoint.endpoint + this.apiendpoint.version;
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
