<div
  class="custom-select-dropdown-field"
  [ngClass]="{ 'custom-select-dropdown-field--selected': showDropdown }"
  [ngStyle]="{ width: width + widthUnit }"
  (mouseleave)="showDropdown = false"
>
  <div
    class="custom-dropdown"
    [ngClass]="{
      'open-dropdown': !disabled && showDropdown,
      'custom-dropdown--for-modal': forModal
    }"
    #SelectDropdown
  >
    <div class="custom-dropdown-item" *ngIf="options && options.length > 0">
      <div
        class="custom-dropdown-normal-checkbox"
        tabindex="0"
        role="status"
        (keyup.enter)="checkAll($event)"
        (click)="checkAll($event)"
        [ngClass]="{ 'green-bg': isAllChecked }"
      ></div>
      <div>{{ allText }}</div>
    </div>

    <div class="custom-dropdown-item" *ngFor="let option of options">
      <div
        class="custom-dropdown-normal-checkbox"
        (click)="onClick($event, option.key)"
        tabindex="0"
        role="listitem"
        (keyup.enter)="onClick($event, option.key)"
        [ngClass]="{ checked: value.includes(option.key) }"
      ></div>
      <div class="custom-dropdown-item__text-div" [title]="option.value">
        {{ option.value }}
      </div>
    </div>
  </div>
  <div class="custom-select-dropdown-field__input" #inputField>
    <div
      class="custom-select-dropdown-field__input__label"
      *ngIf="fieldTitle"
      [ngClass]="{
        'custom-select-dropdown-field__input__label--required': required,
        'custom-select-dropdown-field__input__label--texttransform':
          noTextTransform
      }"
    >
      {{ fieldTitle }}:
    </div>
    <input
      type="text"
      class="custom-select-dropdown-field__input__value"
      [placeholder]="!fieldTitle ? placeholder : ''"
      readonly
      [ngClass]="{
        disabled: disabled,
        'custom-select-dropdown-field__input__value__right-top-bottom-border':
          !fieldTitle
      }"
      [ngStyle]="{ height: height + heightUnit }"
      tabindex="0"
      role="textbox"
      (keyup.enter)="openDropdown()"
      (click)="openDropdown()"
      [value]="displayValue"
      title="{{ displayValue }}"
    />
  </div>
</div>
